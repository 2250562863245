import React from 'react'
import { Box, Typography } from '@mui/material'

const ComponentStep: React.FC = () => {
  return (
    <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 4 }}>
      <Typography variant="h6">Components Step</Typography>
    </Box>
  )
}

export default ComponentStep
