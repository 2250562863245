import { parseISO } from 'date-fns'
import { ID, Label, OrganizationIDs, PaginationInfo, Timestamps } from './common'

type DateFormat = string // Expected format: MM-DD-YYYY

export interface Period extends ID, Omit<OrganizationIDs, 'rootOrganizationId'>, Label, Timestamps {
  startDate: DateFormat
  endDate: DateFormat
  children: Period[]
}

export interface PeriodsResponse {
  periods: Period[]
  paginationInfo: PaginationInfo
}

export const getOrderedLabels = (period: Period): string[] => {
  const labels: string[] = []
  const queue: Period[] = [period]

  while (queue.length > 0) {
    const currentPeriod = queue.shift()!
    labels.push(currentPeriod.label)

    if (currentPeriod.children) {
      queue.push(...currentPeriod.children)
    }
  }

  return labels
}

export const createIdToPeriod = (period: Period): Record<string, Period> => {
  if (!period || typeof period !== 'object') {
    console.error('Invalid period data:', period)
    return {}
  }

  const result: Record<string, Period> = {}

  const traverse = (p: Period) => {
    if (p.id) {
      result[p.id] = p
    }
    if (p.children && Array.isArray(p.children)) {
      p.children.forEach(traverse)
    }
  }

  traverse(period)
  return result
}

export const createLabelToIdMap = (period: Period): Record<string, string> => {
  const map: Record<string, string> = {}

  const traverse = (period: Period) => {
    map[period.label] = period.id || ''
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}

export const createIdLabelMap = (period: Period): Record<string, string> => {
  const map: Record<string, string> = {}

  const traverse = (period: Period) => {
    if (period.id) {
      map[period.id] = period.label || ''
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}

export const parsePeriodDate = (date: string): Date => {
  const dateParts = date.split('-')
  const formattedDate = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
  return parseISO(formattedDate)
}

export const createPeriodToRootPeriodMap = (periods: Period[]): Record<string, string> => {
  const map: Record<string, string> = {}

  const traverse = (period: Period, rootPeriodId: string) => {
    if (period.id) {
      map[period.id] = rootPeriodId
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach((child) => traverse(child, rootPeriodId))
    }
  }

  periods.forEach((rootPeriod) => {
    if (rootPeriod.id) {
      traverse(rootPeriod, rootPeriod.id)
    }
  })

  return map
}

const differenceInDays = (startDate: Date, endDate: Date): number => {
  const oneDay = 24 * 60 * 60 * 1000
  const diffDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay))
  return diffDays
}

const getDaysInPeriod = (period: Period): number => {
  const startDate = parsePeriodDate(period.startDate)
  const endDate = parsePeriodDate(period.endDate)
  return differenceInDays(endDate, startDate)
}

export const createIdDaysMap = (period: Period): Record<string, number> => {
  const map: Record<string, number> = {}

  const traverse = (period: Period) => {
    if (period.id) {
      map[period.id] = getDaysInPeriod(period)
    }
    if (period.children && period.children.length > 0) {
      period.children.forEach(traverse)
    }
  }

  traverse(period)
  return map
}
