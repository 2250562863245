import React, { FC, useEffect, useState, Suspense } from 'react'
import { CircularProgress, Box, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { getRootOrganizationId } from 'types/user-profile'
import { usePeriods } from 'hooks/usePeriods'
import { getCurrentRootPeriod } from 'services/periodServices'
import { Period } from 'types/periods'
import PeriodsWizard from 'pages/Admin/Periods/PeriodsWizard'
import PeriodsTable from 'components/PeriodsTable'

const PeriodStep: React.FC = () => {
  const { token } = useAuth()
  const [currentPeriod, setCurrentPeriod] = useState<Period | null>(null)

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ManagePeriodsContent token={token!} currentPeriod={currentPeriod} setCurrentPeriod={setCurrentPeriod} />
    </Suspense>
  )
}

const ManagePeriodsContent = ({
  token,
  currentPeriod,
  setCurrentPeriod,
}: {
  token: string
  currentPeriod: Period | null
  setCurrentPeriod: (period: Period | null) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    getRootOrganizationId(profile) || '',
    !!token
  )

  useEffect(() => {
    if (!isLoading) {
      console.log('Root Org: ', getRootOrganizationId(profile))
      console.log('Periods: ', fetchedPeriods)
      console.log('Current Period: ', getCurrentRootPeriod(fetchedPeriods.periods))
      setCurrentPeriod(getCurrentRootPeriod(fetchedPeriods.periods))
    }
  }, [isLoading, profile, fetchedPeriods, setCurrentPeriod])

  useEffect(() => {
    setIsLoading(isLoadingProfile || isLoadingPeriods)
  }, [isLoadingProfile, isLoadingPeriods])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <PeriodsWizard rootOrganizationID={getRootOrganizationId(profile)} existingPeriods={fetchedPeriods.periods} />

          <Box
            sx={{
              padding: 2,
              border: '1px solid #ccc',
              borderRadius: 4,
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between" gap={2}>
              <Typography variant="h6">Existing Periods</Typography>
              <Typography variant="h6">Current Root Period: {currentPeriod?.label}</Typography>
            </Box>

            <PeriodsTable periods={fetchedPeriods.periods} />
          </Box>
        </>
      )}
    </Box>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

export default PeriodStep
