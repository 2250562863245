import React from 'react'
import { Box, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

const TeamStep: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        gap: 2,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to="/organization"
        sx={{
          mt: 2,
          alignSelf: 'flex-start',
          width: '25%',
          maxWidth: '200px',
        }}
      >
        Manage Organization
      </Button>
    </Box>
  )
}

export default TeamStep
