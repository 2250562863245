import React from 'react'
import { Box, Button, Stepper, Step, StepLabel, Typography } from '@mui/material'
import TeamStep from './TeamStep'
import PeriodStep from './PeriodStep'
import ComponentStep from './ComponentStep'
import TemplateStep from './TemplateStep'
import PlanStep from './PlanStep'
import useLocalStorage from 'hooks/useLocalStorage'
import SimpleTitle from 'components/SimpleTitle'

const steps = ['Organization', 'Periods', 'Components', 'Templates', 'Plans', 'Success']

const Wizard: React.FC = () => {
  const [activeStep, setActiveStep] = useLocalStorage<number>('purcent_active_wizard_step', 0)

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <TeamStep />
      case 1:
        return <PeriodStep />
      case 2:
        return <ComponentStep />
      case 3:
        return <TemplateStep />
      case 4:
        return <PlanStep />
      case 5:
        return 'Step 5 - Success Step'
      default:
        return 'Unknown step'
    }
  }

  return (
    <div>
      <Box>
        <SimpleTitle title="Wizard" />
        <Box
          sx={{
            height: '80%',
            width: '90%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {}
              const labelProps: { optional?: React.ReactNode } = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </div>
  )
}

export default Wizard
